import React from 'react';

const FatArrowIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={38}
    height={38}
    viewBox="0 0 38 38"
    fill="none"
  >
    <path
      d="M38 18.75L19.25 0L0.5 18.75H9.875V35.9375C9.875 36.3519 10.0396 36.7493 10.3326 37.0424C10.6257 37.3354 11.0231 37.5 11.4375 37.5H27.0625C27.4769 37.5 27.8743 37.3354 28.1674 37.0424C28.4604 36.7493 28.625 36.3519 28.625 35.9375V18.75H38Z"
      fill="#010812"
    />
  </svg>
);

export default FatArrowIcon;
