import { Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

import FatArrowIcon from '../icons/fat-arrow';
import { Box, Col, Row } from '../layout';

interface EnergyChartProps {
  primaryEnergyConsumption: number;
  finalEnergyConsumption: number;
}

const ENERGY_CHART_BASE_WIDTH = 250;
const ENERGY_CHART_INDICATOR_SIZE = 20;

const ENERGY_CHART_LABELS_CONFIG = [
  'A+',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
];
const ENERGY_CHART_VALUES_CONFIG = [
  0,
  25,
  50,
  75,
  100,
  125,
  150,
  200,
  225,
  250,
];

const EnergyChart: React.FC<EnergyChartProps> = ({
  finalEnergyConsumption,
  primaryEnergyConsumption,
}) => {
  const barElementRef = useRef<HTMLDivElement>();
  const [offsetWidthDiff, setOffsetWidthDiff] = useState<number>();

  const makeEnergyIndicator = (energyValue: number, className: string) => {
    const indicatorPlacement = energyValue * offsetWidthDiff;

    return (
      <div
        className={className}
        style={{
          left: `${
            energyValue === ENERGY_CHART_BASE_WIDTH
              ? indicatorPlacement - ENERGY_CHART_INDICATOR_SIZE
              : indicatorPlacement + ENERGY_CHART_INDICATOR_SIZE
          }px`,
        }}
      >
        <FatArrowIcon />
      </div>
    );
  };

  const makeLabel = (title: string, value: number) => (
    <Box>
      <Typography.Paragraph>{title}</Typography.Paragraph>
      <Typography.Paragraph>
        <strong>{value}</strong>
        kWh/(m<sup>2</sup>*a)
      </Typography.Paragraph>
    </Box>
  );

  useEffect(() => {
    if (barElementRef?.current) {
      setOffsetWidthDiff(
        barElementRef?.current?.offsetWidth / ENERGY_CHART_BASE_WIDTH,
      );
    }
  }, [barElementRef]);

  return (
    <>
      {makeLabel('Endenergieverbrauch', finalEnergyConsumption)}

      <div
        className="real-estate-details-energy-section__bar"
        ref={barElementRef}
      >
        {makeEnergyIndicator(
          finalEnergyConsumption,
          'final-energy-consumption',
        )}

        <Row className="real-estate-details-energy-section__bar-label">
          {ENERGY_CHART_LABELS_CONFIG.map((label, index) => (
            <Col key={index} center>
              {label}
            </Col>
          ))}
        </Row>

        <Box
          className="real-estate-details-energy-section__bar-value"
          justify="space-between"
        >
          {ENERGY_CHART_VALUES_CONFIG.map((value, index) => (
            <Box key={index} style={{ padding: '0 5px' }}>
              {ENERGY_CHART_VALUES_CONFIG.length - 1 === index && '>'}
              {value}
            </Box>
          ))}
        </Box>

        <Row className="real-estate-details-energy-section__bar-label">
          {ENERGY_CHART_LABELS_CONFIG.map((label, index) => (
            <Col key={index} center>
              <div style={{ visibility: 'hidden' }}>{label}</div>
            </Col>
          ))}
        </Row>

        {makeEnergyIndicator(
          primaryEnergyConsumption,
          'primary-energy-consumption',
        )}
      </div>

      {makeLabel('Primärenergieverbrauch', primaryEnergyConsumption)}
    </>
  );
};

export default EnergyChart;
