import '../styles/real-estate-details.scss';

import { useLocation } from '@reach/router';
import { Button, Typography } from 'antd';
import { navigate } from 'gatsby';
import React from 'react';

import Boilerplate from '../components/boilerplate';
import Carousel from '../components/carousel';
import ContactDetails from '../components/common/contact-details';
import EnergyChart from '../components/common/energy-chart';
import { Map } from '../components/common/map';
import SectionHeading from '../components/common/section-heading';
import CtaSection from '../components/cta-section';
import Form from '../components/form';
import ArrowSimpleLeftIcon from '../components/icons/arrow-simple-left';
import Image from '../components/image';
import { Box, Col, Container, Row, Section } from '../components/layout';
import { RealEstateItemMainParameters } from '../components/real-estate-item';
import { richTextToReactComponents } from '../components/utils';
import { PageLocalContext } from '../context';
import {
  PageThemeMode,
  RealEstateDetailsTemplateContext,
  StaticPageId,
  useAboutSectionItemData,
  useCarouselSectionData,
} from '../data';
import { useLinkResolver } from '../hooks';
import { Template } from '../types';
import { isEmpty } from '../utils';
import { classNameMap } from '../utils/tsx';

const CONTACT_FORM_ELEMENT_ID = 'contact-form';

const RealEstateDetailsTemplate: Template<RealEstateDetailsTemplateContext> = ({
  pageContext: { id, data },
}) => {
  const linkResolver = useLinkResolver();
  const { data: contactPersonData } = useAboutSectionItemData(
    data?.contactPerson?.id,
  );
  const { data: carouselSectionData } = useCarouselSectionData(
    data?.carousel?.id,
  );
  const location = useLocation();

  return (
    <PageLocalContext.Provider value={{ pageId: StaticPageId.RealEstates }}>
      <Boilerplate
        seoId={data.seo.id}
        pageId={id}
        pageTheme={{ navigationMode: PageThemeMode.Dark }}
      >
        <Section className="real-estate-details-section">
          <Container>
            <Button
              className="enhanced-button back-button"
              type="link"
              onClick={() => navigate(linkResolver(StaticPageId.RealEstates))}
              aria-label="Zurück"
            >
              <ArrowSimpleLeftIcon />
              Zurück
            </Button>

            <Carousel
              slides={(carouselSectionData?.items
                ? [...carouselSectionData?.items]
                : [{ image: data.image }]
              ).map((item, index) => ({
                component: (
                  <Box key={index}>
                    <Image id={item?.image?.id} />
                  </Box>
                ),
              }))}
            />

            <Section className="real-estate-details-definition-section">
              <Box className="real-estate-details-definition-section__box-content">
                <Box className="real-estate-details-definition-section__header">
                  <Typography.Title level={4}>{data.title}</Typography.Title>
                </Box>

                <Box justify="space-between" align="middle">
                  <Box>
                    <RealEstateItemMainParameters
                      item={data}
                      bordered
                      showRealEstateType
                    />
                  </Box>
                  <Box>
                    <Button
                      className="enhanced-button"
                      type="primary"
                      block
                      onClick={() =>
                        navigate(
                          `${location.pathname}#${CONTACT_FORM_ELEMENT_ID}`,
                        )
                      }
                      aria-label="Exposé anfordern"
                    >
                      Exposé anfordern
                    </Button>
                  </Box>
                </Box>

                <Box className="real-estate-details-definition-section__parameters-group">
                  {richTextToReactComponents(data.description)}
                </Box>

                <Box className="real-estate-details-definition-section__parameters">
                  {data.parameters.map((parameter, index) => (
                    <Box
                      key={index}
                      className="real-estate-details-definition-section__parameters-group"
                    >
                      <Typography.Title level={4}>
                        {parameter.title}
                      </Typography.Title>
                      {parameter?.items?.map((item, index) => {
                        const shouldHideNameColumn =
                          Boolean(item.icon?.id) || isEmpty(item.name);

                        return (
                          <Row
                            key={index}
                            justify="start"
                            align="middle"
                            className="real-estate-details-definition-section__parameters-group-item"
                          >
                            <Col
                              hidden={shouldHideNameColumn}
                              span={{ sm: 12, md: 6 }}
                            >
                              <Box>{item.name}</Box>
                            </Col>
                            <Col
                              span={{
                                sm: shouldHideNameColumn ? 24 : 12,
                                md: 18,
                              }}
                            >
                              <Box
                                align="middle"
                                className={classNameMap({
                                  'real-estate-details-definition-section__parameters-group-item-value': !shouldHideNameColumn,
                                })}
                              >
                                <Image id={item.icon.id} />
                                {item.value}
                              </Box>
                            </Col>
                          </Row>
                        );
                      })}
                    </Box>
                  ))}
                </Box>
              </Box>
            </Section>

            <Section>
              <Map
                coordinates={{
                  lat: data.location?.lat,
                  lng: data.location?.lon,
                }}
                showCircle
                mapInstanceOptions={{
                  zoom: 13,
                  maxZoom: 13,
                }}
              />
            </Section>

            {data?.finalEnergyConsumption && data?.primaryEnergyConsumption && (
              <Section className="real-estate-details-energy-section">
                <SectionHeading title={'Energieverbrauch'} />
                <EnergyChart
                  finalEnergyConsumption={data.finalEnergyConsumption}
                  primaryEnergyConsumption={data.primaryEnergyConsumption}
                />
              </Section>
            )}

            <Section className="real-estate-details-contact-section">
              <div id={CONTACT_FORM_ELEMENT_ID}>
                <SectionHeading title={'Kontakt'} />

                <Row gutters="2rem">
                  <Col span={{ sm: 24, md: 11 }}>
                    <Row gutters="1rem">
                      <Col span={{ sm: 24, md: 12 }}>
                        <Image id={contactPersonData.image.id} />
                      </Col>
                      <Col span={{ sm: 24, md: 12 }}>
                        <Box justify="center" direction="column" fullHeight>
                          <Typography.Paragraph strong>
                            {contactPersonData.fullName}
                          </Typography.Paragraph>

                          <Typography.Paragraph>
                            {contactPersonData.position}
                          </Typography.Paragraph>
                        </Box>
                      </Col>
                    </Row>

                    <ContactDetails showAddress />
                  </Col>

                  <Col span={{ sm: 24, md: 13 }}>
                    <Form id={data?.form?.id} />
                  </Col>
                </Row>
              </div>
            </Section>
          </Container>

          {data?.ctaSection?.id && <CtaSection id={data?.ctaSection?.id} />}
        </Section>
      </Boilerplate>
    </PageLocalContext.Provider>
  );
};

export default RealEstateDetailsTemplate;
